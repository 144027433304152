<template>
    <main-layout>
        <template #content>
            <header-content :header-info="headerInfo" />

            <div class="manual-wrapper">
                <div
                    v-for="(referenc, index) in $options.referencingList"
                    :key="index"
                >
                    <h3 class="manual-article">
                        {{ referenc.title }}
                    </h3>
                    <p class="manual-text">
                        {{ referenc.description }}
                    </p>
                    <RefAndPlagManuaisItem
                        v-for="(item,indexItem) in referenc.items"
                        :key="indexItem"
                        :item="item"
                    />
                </div>
            </div>
        </template>
    </main-layout>
</template>

<script>

import MainLayout from '@/layouts/MainLayout.vue'
import HeaderContent from '@/components/HeaderContent';
import RefAndPlagManuaisItem from '@/components/pages/documents/RefAndPlagManuaisItem.vue';
import { referencingList } from '@/helpers/documents/referencingList'
import { documentsBreadcrumbsStepsPages } from '@/helpers/documents/breadcrumbsStepsList';

export default {
    referencingList,
    documentsBreadcrumbsStepsPages,
    name: 'ReferencingAndPlagiarismManuals',
    components: {
        MainLayout,
        HeaderContent,
        RefAndPlagManuaisItem
    },
    metaInfo: {
        title: 'Referencing & Plagiarism Manuals'
    },
    data() {
        return {
            headerInfo: {
                title: 'Referencing & Plagiarism Manuals',
                className: 'header-content__documents',
                steps: this.$options.documentsBreadcrumbsStepsPages
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.manual-title{
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 56px;
    color: $white;
}

.manual-wrapper {
    max-width: 1200px;
    margin: 0 auto 20px;
    border: 1px solid #e7ecf4;
    color: #181818;
    padding: 30px 40px;
    //font-family: Rubik;
    position: relative;
    top:-30px;
    background-color: white;
}

@media (max-width: 1200px){
    .manual-wrapper  {
        padding: 30px 20px;
    }
}

.manual-article {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 30px;
}

.manual-text {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 20px;
}
</style>
