export const referencingList = Object.freeze({
    formattingPolicy: {
        title: 'Formatting Policy',
        description: 'Your paper must be formatted in accordance with the following specifications: 12 point Times New Roman font, 1 inch margins, double spacing, no gaps between paragraphs. A bibliography (works cited page) must accompany every project and does not count toward the total number of pages required.',
        items: [{
            title: 'Sample 1 - MLA style. ',
            text: 'This document demonstrates the basics of MS Word text formatting: double spaced pages, 12 point Times New Roman font, 1 inch margins on all sides, Works Cited formatted as a separate page (press CTRL+ENTER in MS Word).',
            link: ''
        }]
    },
    referencingTutorials: {
        title: 'Referencing Tutorials',
        description: 'Downloadable tutorials on how to reference sources and compose a correct Works Cited page.',
        items: [
            {
                title: 'A helpful MLA formatting tutorial.',
                text: '',
                link: ''
            },
            {
                title: 'A research paper formatting tutorial (MLA style).',
                text: '',
                link: ''
            },
            {
                title: 'A referencing tutorial.',
                text: '',
                link: ''
            }
        ]
    },
    plaiarism: {
        title: 'Plagiarism',
        description: 'Our selection of sources on plagiarism that define plagiarism and teach how to avoid it.',
        items: [
            {
                title: 'A nice guide to avoiding plagiarism and writing authentic papers',
                text: '',
                link: ''
            },
            {
                title: 'Academic definition of plagiarism. Plagiarism tutorial.',
                text: '',
                link: ''
            }
        ]
    }
})
