export const documentsBreadcrumbsSteps = Object.freeze([
    {
        title: 'Home',
        url: '/'
    },
    {
        title: 'Dashboard',
        url: '/cp/account'
    }
])

export const documentsBreadcrumbsStepsPages = Object.freeze([
    {
        title: 'Home',
        url: '/'
    },
    {
        title: 'Dashboard',
        url: '/cp/account'
    },
    {
        title: 'Documents',
        url: '/cp/documents'
    }
])
