<template>
    <div class="list">
        <p class="list__title">
            {{ item.title }}
        </p>
        <p class="list__text">
            {{ item.text }}
        </p>
        <p>
            <a
                href="#"
                class="btn-base btn-main list__btn"
            >Download</a>
        </p>
    </div>
</template>

<script>
export default {
    name: 'RefAndPlagManuaisItemVue',
    props: {
        item: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>

.list {
    background: rgba(17, 97, 224, 0.07);
    border-radius: 4px;
    padding: 30px;
    margin-bottom: 20px;
    color: #181818;
    //font-family: Rubik;
    &:last-of-type{
        margin-bottom: 40px;
        border-bottom: 1px solid #e7ecf4;
    }
    &__title {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
    }
    &__text{
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
    }
    &__btn{
        margin-top: 30px;
        @media (max-width: 550px){
            width: 100%;
        }
    }
}

</style>
